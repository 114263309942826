import { Route, Routes, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'
import { MicroFrontend } from '@praxis/component-microfrontend'
import featureFlag from '../util/featureFlag'
import { NotFound, ProtectedElement } from '@dlm/common'

import { ErrorContent } from './ErrorContent'

import Home from '../views/Home'

import useUser from '../hooks/useUser'

import apiConfig from '../config/apiConfig'
import { Admin } from '../views/Admin/Admin'

export const MainRouter = () => {
  const location = useLocation()
  const {
    access: {
      isUser,
      isAdmin,
      isCarrier,
      isAuction,
      isCarrierEdiOnboardingUser,
      isPrivateFleetAdmin,
    },
  } = useUser()

  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        {/* Application routes*/}
        <Route path="/" element={<Home />} />

        {isAdmin && <Route path="/admin" element={<Admin />} />}

        {(isUser || isAdmin || isCarrier) && (
          <>
            <Route path="/loadtracking">
              <Route
                path=""
                element={
                  <MicroFrontend
                    src={apiConfig.mfe.loadTracking}
                    module="./LoadTracking"
                  />
                }
              />
              <Route
                path="dashboard"
                element={
                  <MicroFrontend
                    src={apiConfig.mfe.loadTracking}
                    module="./Dashboard"
                  />
                }
              />
            </Route>
            {featureFlag(
              ['prod'],
              <Route
                path="/loadmanagement"
                element={
                  <MicroFrontend
                    src={apiConfig.mfe.loadManagement}
                    module="./LoadManagement"
                  />
                }
              />,
            )}
            {featureFlag(
              ['prod'],
              <Route
                path="/dispatch"
                element={
                  <MicroFrontend
                    src={apiConfig.mfe.dispatchView}
                    module="./DispatchView"
                  />
                }
              />,
            )}
            <Route
              path="/departurelog"
              element={
                <MicroFrontend
                  src={apiConfig.mfe.departureLog}
                  module="./DepartureLog"
                />
              }
            />
            ,
          </>
        )}

        {isPrivateFleetAdmin && (
          <Route path="/fleetmanagement">
            <Route
              path=""
              element={
                <MicroFrontend
                  src={apiConfig.mfe.fleetManagement}
                  module="./Driver"
                />
              }
            />
          </Route>
        )}

        {(isUser || isAdmin || isCarrier || isAuction) && (
          <>
            <Route
              path="/loadboard"
              element={
                <MicroFrontend
                  src={apiConfig.mfe.loadBoard}
                  module="./LoadBoard"
                />
              }
            />
            ,
            <Route
              path="/carrier-eligibility"
              element={
                <ProtectedElement
                  allowed={apiConfig.auth.requiredPermissions.admin}
                >
                  <MicroFrontend
                    src={apiConfig.mfe.loadBoard}
                    module="./CarrierEligibility"
                  />
                </ProtectedElement>
              }
            />
            ,
            <Route
              path="/auction-configuration"
              element={
                <ProtectedElement
                  allowed={apiConfig.auth.requiredPermissions.admin}
                >
                  <MicroFrontend
                    src={apiConfig.mfe.loadBoard}
                    module="./AuctionConfiguration"
                  />
                </ProtectedElement>
              }
            />
            ,
            <Route
              path="/notifications"
              element={
                <MicroFrontend
                  src={apiConfig.mfe.notifications}
                  module="./Notifications"
                />
              }
            ></Route>
            ,
          </>
        )}

        {isCarrierEdiOnboardingUser && (
          <Route
            path="/carrieronboarding"
            element={
              <MicroFrontend
                src={apiConfig.mfe.carrieronboarding}
                module="./CarrierOnboarding"
              />
            }
          />
        )}

        {/*404*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  )
}
