import { useEffect, useState } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'

import { MainRouter } from '../components/MainRouter'
import { Header } from '../components/Header'
import { SideNavigation } from '../components/SideNavigation'

import { useAuth } from '@praxis/component-auth'
import { ConfirmActionModal } from '../components/ConfirmActionModal'

export const Main = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false)
  const openSideNav = () => {
    setIsSideNavOpen(true)
  }
  const closeSideNav = () => {
    setIsSideNavOpen(false)
  }

  const auth = useAuth()

  useEffect(() => {
    const sessionExpirationMs = auth?.session?.access?.exp * 1000 - Date.now()
    setTimeout(() => {
      setIsLoginModalVisible(true)
    }, sessionExpirationMs)
  }, [auth])

  const handleLoginPressed = async () => {
    await auth.session.validate()
    if (!auth.session.isAuthenticated()) {
      auth.login()
    }
    setIsLoginModalVisible(false)
  }

  const handleCloseSessionModal = async () => {
    await auth.session.validate()
    if (!auth.session.isAuthenticated()) {
      auth.logout()
    }
    setIsLoginModalVisible(false)
  }

  return (
    <>
      <ConfirmActionModal
        headingText="Your session has expired, please login again."
        challengeText=""
        confirmButtonText="Login"
        confirmButtonAction={handleLoginPressed}
        cancelButtonText="Logout"
        cancelButtonAction={handleCloseSessionModal}
        isVisible={isLoginModalVisible}
      />
      <Layout
        theme="target" // configure theme here
        darkMode="system" // configure dark mode here
        data-testid="mainAuthenticatedLayout"
        fullWidth
      >
        <Header onSideNavOpen={openSideNav} />
        <div className="side-nav">
          <SideNavigation
            isVisible={isSideNavOpen}
            onRequestClose={closeSideNav}
          />
        </div>
        <Layout.Body includeRail className="topPage-layout-body">
          <MainRouter />
        </Layout.Body>
      </Layout>
    </>
  )
}
